export const richTextToPlainText = (raw) => {
  let rawParsed = null

  try {
    rawParsed = JSON.parse(raw)
  } catch (e) {
    console.log(e)
  }

  const plainTextArray = []
  const content = rawParsed?.content

  if (!content?.length) {
    return ""
  }

  content.forEach((node) => {
    if (node.nodeType === "paragraph" && node.content) {
      // Extract plain text from paragraph
      const paragraphText = node.content
        .map((textNode) => {
          // Handle hyperlink nodes within the paragraph
          if (textNode.nodeType === "hyperlink" && textNode.content) {
            const linkText = textNode.content
              .map((linkNode) => linkNode.value || "")
              .join("")
            const url = textNode.data.uri
            return `${linkText} (${url})`
          }

          // Handle regular text nodes
          return textNode.value || ""
        })
        .join("")

      plainTextArray.push(paragraphText)
    }
  })

  return plainTextArray.join()
}
