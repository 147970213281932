import { richTextToPlainText } from "../richTextToPlainText"

export const getFaqSchema = (rawContent, references) => {
  const parsedData = JSON.parse(rawContent)
  const faqList = []

  parsedData.content.forEach((item) => {
    if (item.nodeType === "embedded-entry-block") {
      // Handle embedded entries (question-answer links)
      const embeddedEntryId = item.data.target.sys.id
      const linkedEntry = references.find(
        (ref) => ref.contentful_id === embeddedEntryId
      )
      const items = linkedEntry.items
      if (items?.length === 0) return
      // for each item push to faqList
      items.forEach((item) => {
        const answer = richTextToPlainText(item.body.raw)
        faqList.push({
          "@type": "Question",
          name: item.title,
          acceptedAnswer: {
            "@type": "Answer",
            text: answer,
          },
        })
      })
    }
  })

  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqList,
  }
}
